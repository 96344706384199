@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;800;900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";

/* Additional styles */
@import "additional-styles/utility-patterns.css";
@import "additional-styles/range-slider.css";
@import "additional-styles/toggle-switch.css";
@import "additional-styles/theme.css";

@import "tailwindcss/utilities";

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
  .rtl {
    direction: rtl;
  }
}

a {
  cursor: pointer;
}

/* See Alpine.js: https://github.com/alpinejs/alpine#x-cloak */
[x-cloak=""] {
  display: none;
}
.gradient-button-bg {
  background: linear-gradient(90deg, #94b9ff 0%, #cdffd8 100%);
}
.gradient-button-bg-hover {
  background: linear-gradient(90deg, #cdffd8 0%, #94b9ff 100%);
}
.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.gradient-bg {
  background: #edf5ef;
}

.gradient-bg2 {
  background: #eef9f1;
}
/* .gradient-bg2 {
  background: linear-gradient(0deg, #94b9ff 0.25%, #cdffd8 99.72%);
} */

#ring {
  animation: rings 2s ease-in-out infinite alternate;
  transform-origin: left;
}

@keyframes rings {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(20px);
  }
}

#convertkit-form-container {
  max-width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0 auto; /* Centering the form */
  text-align: center; /* Ensure content inside the form is centered */
  background: #1a202c; /* Adjusted background color to match your theme */
  border: 1px solid #ddd; /* Example border, adjust as needed */
  border-radius: 8px; /* Rounded corners, adjust as needed */
  color: #ffffff; /* Text color adjustment for contrast */
}

/* Additional custom styles for better alignment and look */
.convertkit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px; /* Adjust the height as needed */
}

.convertkit-form input,
.convertkit-form button {
  width: 100%; /* Full width for input and button */
  margin: 0.5rem 0; /* Space between elements */
}

.convertkit-form button {
  background-color: #3182ce; /* Button background color */
  color: #ffffff; /* Button text color */
}

.postlist {
  width: 40%;
  margin: auto;
  text-align: center;
}

.post {
  width: 40%;
  margin: auto;
}

.page-content {
  width: 40%;
  margin: auto;
  text-align: center;
}

.notfound {
  text-align: center;
}

.notfound {
  text-align: center;
}

.post h1 {
  /* font-family: "Raleway"; */
  font-weight: 700;
  /* margin: 50 0 0 0; */
}

.links {
  text-decoration: none;
  color: darkblue;
}

.layout-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 90vh;
  padding: 20px;
}

.header {
  background: navy;
  color: lightblue;
  padding: 10px 20px;
}

.navbar {
  display: flex;
  justify-content: space-around;
}

.footer {
  text-align: center;
}

.title {
  margin-bottom: 20px;
}

.post-card {
  text-align: left;
  font-family: "Raleway";
  border: 1px solid lightblue;
  margin-bottom: 20px;
  padding: 0 20px;
}

.img-container {
  display: flex;
}

.thumbnail {
  padding: 10px;
}

@media (max-width: 540px) {
  .post {
    width: 100% !important;
  }
}
