.boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  color: #555555;
}

.box {
  padding: 6px 12px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  width: 15%;
  height: 150px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.green_dot {
  width: 6px;
  height: 6px;
  background-color: #65c084;
  border-radius: 50%;
  display: inline-block;
}

.pnl_text {
  font-size: 0.8em;
}

.box:hover {
  transform: scale(1.1);
}

.text_div h5 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}

.text_div h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  max-height: 70px;
  min-height: 70px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.icon_div {
  padding: 8px;
  align-self: self-end;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_div .icon {
  color: #888888;
  font-size: 20px;
}

/* PortfolioGraph styling */

.container {
  display: flex;
  gap: 2rem;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin: 1.5rem 0;
  padding: 0 20px;
}

.table_container,
.graph_container {
  width: 50%;
  padding: 10px 4px;
  background-color: white;
  border-radius: 20px;
}

.table_container h3 {
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 0;
}

.data_table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.header_cell {
  padding: 4px;
  /* background-color: #f2f2f2; */
  text-align: left;
}

.data_cell {
  padding: 4px;
  font-size: 16px;
}

.green {
  color: green;
}

.data_row {
  cursor: pointer;
}
.data_row:hover {
  background-color: #f2f2f2;
}

.button_title_wrapper {
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px;
}

.button_title_wrapper p {
  font-size: 14px;
  font-weight: 600;
}

.button_container {
  background-color: #edf4ef;
  border-radius: 4px;
  padding: 1px;
}

.graph_button {
  margin: 0 3px;
  padding: 2px 6px;
  font-size: 12px;
  /* background-color: #007bff; */
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.graph_button:hover {
  background-color: #96bbfe;
  color: white;
}

.graph_button.active {
  background-color: #96bbfe;
  color: white;
}

.table_container {
  margin-bottom: 20px;
}

.data_table {
  width: 100%;
  border-collapse: collapse;
}

.header_cell {
  text-align: left;
  padding: 10px;
  font-weight: bold;
}

.data_row {
  cursor: pointer;
}

.data_cell {
  text-align: left;
  padding: 10px;
}

.button_title_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.graph_button {
  margin-right: 5px;
}

.graph_button.active {
  font-weight: bold;
}

.bar_line {
  border-top: 1px solid #e0e0e0;
}

.green_dot {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.pnl_text {
  color: green;
}

/* Responsive Design for Mobile Screens */
@media (max-width: 768px) {
  .box {
    width: 45%;
    /* height: auto; */
  }

  .container {
    flex-direction: column;
    gap: 1rem;
    /* padding: inherit; */
  }

  .table_container,
  .graph_container {
    width: 100%;
  }

  .table_container h3 {
    font-size: 18px;
    margin-left: 10px;
  }

  .data_cell {
    font-size: 14px;
  }

  .button_title_wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .button_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .graph_button {
    flex: 1;
    margin: 5px 0;
  }
}

/* @media (max-width: 520) {
  .box {
    width: 45%;
  }

  .text_div h5 {
    font-size: 10px;
  }

  .text_div h2 {
    font-size: 18px;
    max-height: none;
    min-height: auto;
  }

  .icon_div .icon {
    font-size: 18px;
  }

  .table-container h3 {
    font-size: 16px;
  }

  .data-cell {
    font-size: 14px;
  }
} */
