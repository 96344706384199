.post {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
}

.post-header {
  text-align: center;
}

.post-title {
  font-size: 2rem;
  margin-bottom: 10px;
}

.post-thumbnail {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.post-meta {
  display: flex;
  justify-content: center;
  gap: 15px;
  font-size: 0.9rem;
  color: #555;
}

.post-meta .post-updated {
  color: #28a745;
}

.post-divider {
  margin: 20px 0;
}

.post-content {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

.post-chapters {
  margin-bottom: 30px;
  text-align: center;
}

.post-chapters h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.chapter-carousel {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.chapter-card {
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 150px;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.4;
}

.chapter-carousel::before,
.chapter-carousel::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #e0e0e0;
  border-radius: 50%;
  margin: auto 5px;
}

.post-key-findings {
  margin-bottom: 30px;
}

.post-key-findings h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.post-key-findings ul {
  list-style-type: disc;
  padding-left: 20px;
  font-size: 1.1rem;
  line-height: 1.6;
}

.post-key-findings li {
  margin-bottom: 10px;
}

.postlist {
  padding: 20px;
}

.featured-post {
  width: 100%;
  margin-bottom: 40px;
}

.featured-title {
  font-size: 2em;
  margin-bottom: 10px;
}

.featured-thumbnail {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.related-posts {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.post-card {
  flex: 1;
  max-width: 30%;
  background: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.img-container {
  display: flex;
  align-items: center;
}

.thumbnail {
  width: 80px;
  height: auto;
  margin-right: 10px;
}

.post-title {
  font-size: 1.2em;
  margin: 0;
}

.links {
  color: #3498db;
  text-decoration: none;
}

.footer {
    background-color: #f7fdfd;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px;
    background-color: #e0f7f7;
  }
  
  .footer-top p {
    font-size: 20px;
    margin: 0;
  }
  
  .subscribe {
    display: flex;
    align-items: center;
  }
  
  .subscribe input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .subscribe button {
    padding: 10px 20px;
    background-color: #d5f5d5;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    width: 160px;
  }
  
  .footer-bottom {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #fff;
  }
  
  .footer-section {
    max-width: 200px;
    text-align: left;
  }
  
  .footer-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .footer-section p {
    font-size: 14px;
    color: #666;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
  }
  
  .footer-section ul li:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  .footer-copyright {
    background-color: #fff;
    padding: 10px;
    font-size: 14px;
    color: #666;
  }
  
