#ring {
  animation: rings 50s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes rings {
  from {
    transform: rotateZ(0deg); /* Start position */
  }
  to {
    transform: rotateZ(360deg); /* End position */
  }
}
#rings {
  animation: ringo 50s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes ringo {
  from {
    transform: rotateZ(0deg); /* Start position */
  }
  to {
    transform: rotateZ(360deg); /* End position */
  }
}

.input {
  padding: 1rem;
  border: double 4px transparent;
  border-radius: 100px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #cad4fe, #facfe9);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.loginCard {
  background-color: white;
}

.tab-buttons {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.tab-buttons::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #3b82f6; /* Tailwind blue-500 */
  transition: transform 0.3s ease;
  z-index: 0;
}

.button-left.active::before {
  transform: translateX(0%);
}

.button-right.active::before {
  transform: translateX(100%);
}

.tab-button {
  position: relative;
  padding: 8px 16px;
  border: 1px solid #3b82f6;
  z-index: 1;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.tab-button.active {
  color: white;
  background-color: #3b82f6;
}

.tab-button:not(.active) {
  color: #3b82f6;
  background-color: white;
}


.p-style {
  overflow: hidden;
  font-size: 1.2rem;
  color: #344559; /* Replace this with the actual color value */
}

.p-style-1 {
  margin-bottom: 2.5rem;
}

/* styles.css */

.title-style {
  font-weight: 600;
  font-size: 2.75rem;
  margin-bottom: 1rem; /* equivalent to mb-4 */
  color: #344559; /* assuming --primary-color is defined in your CSS variables */
  letter-spacing: 0em; /* added letter spacing */
  line-height: 1; /* assuming you have an animation for fade-up */
  /* animation: fade-up 1s ease-in-out; */
}

