.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1%;
  margin: 0;
}

.boxes_mobile {
  display: none;
}

.section_first {
  display: flex;
  gap: 1rem;
}

.section_second {
  display: flex;
  gap: 1rem;
}

.section_first_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.section_third_smallimage {
  width: 13px;
  height: 12px;
}

.content {
  padding: 0 8px;
}

.content_section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.content_section p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: #888888;
}

.content p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.main_content {
  display: flex;
  gap: 6px;
  margin: 32px 0px;
}

.section_third_image {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover; /* Optional: Ensures the image covers the area without distortion */
}

.section_third_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-table {
  padding: 6px 18px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 340px;
  height: 645px;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;
}

.custom-table th {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.44px;
  text-align: left;
  color: #aaaaaa;
}

.table_header {
  margin: 0 12px 12px 0;
}

.custom-table td {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.75px;
  text-align: left;
  color: #344559;
  margin: 4px;
}

.custom-table tr {
  display: flex;
  justify-content: space-between;
}

.tr,
.td {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
}

.dots {
  margin: 0 10px;
}

.bar_line {
  width: 100%;
  height: 1px;
  border: 1px;
  background-color: #d0d0d0;
}

.round_shape {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.last_section {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  align-self: self-start;
  padding: 26px 6px;
  gap: 12px;
}

.vert_line {
  height: 300px;
  width: 0.7px;
  border: 0.7px;
  border: solid 0.7px;
  color: #d0d0d0;
  margin: 0;
  padding: 0;
}

.last_section_part {
  display: flex;
  align-items: center;
  gap: 10px;
}

.last_section_part p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
}

.btn_primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #96bbfe;
  width: 100%;
  height: 46px;
  padding: 1.54px 24.62px 1.54px 18.47px;
  border-radius: 12.31px;
  outline: none;
  border: none;
  margin-top: 32px;
  margin-bottom: 8px;
  cursor: pointer;
}

.btn_primary label {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  text-align: left;
  color: #ffffff;
  margin-left: 4px;
  cursor: pointer;
}

.boxes {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  padding: 0 24px;
}

.box {
  padding: 14px 26px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  width: 210px;
  height: 130px;
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.box:hover {
  transform: scale(1.1);
}

.icon_div {
  padding: 8px;
  align-self: self-start;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_div .icon {
  color: #888888;
  font-size: 20px;
}

.text_div {
  margin-top: 14px;
  overflow: hidden;
}

.text_div .title {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
}

.text_div .subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  display: flex;
  align-items: center;
  margin: 8px 0px;
}

.section_second_table {
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 350px;
  height: fit-content;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
}

.section_second_table td {
  font-weight: 500;
}
.section_second_table .header {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 23.44px;
  text-align: left;
  color: #aaaaaa;
  background-color: white;
}

.section_second_table label {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}
.section_second_table tr th {
  margin: 22px 0px;
}
.section_second_table tr {
  display: flex;
  justify-content: space-between;
}

.tr_label {
  font-weight: 600;
  margin: 5px 0;
  font-size: 14px;
}

.small_box {
  width: 63px;
  height: 32px;
  padding: 0px 10px 0px 10px;
  gap: 10px;
  border-radius: 7px 0px 0px 0px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  align-items: center;
  outline: none;
}

.header_digit {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #2ec847;
  background-color: #b4ffce;
}

.section_second_box {
  padding: 18px 22px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 62%;
  height: fit-content;
  border-radius: 20px;
  margin-left: 40px;
  transition: all 0.4s ease-in-;
}

.second_box_header {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.risk-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section_second_small_table {
}

.risk-label {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: start;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.risk-label span {
  color: #65c084;
  margin-left: 4px;
}

.risk-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  outline: none;
  opacity: 0.7;
  transition: "opacity" 0.2s;
  margin: 8px 0 2px 0;
  border-radius: 10px;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  color: #888888;
}

.start-label {
  align-self: flex-start;
}

.end-label {
  align-self: flex-end;
}

.risk-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  background: transparent;
  border: none;
  display: none; /* Hide the thumb */
}

.risk-slider::-moz-range-thumb {
  width: 0;
  height: 0;
  background: transparent;
  border: none;
  display: none; /* Hide the thumb */
}

.section_third {
  display: flex;
  gap: 1rem;
  width: 1415px;
}
.section_third_container {
  padding: 18px 22px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 90%;
  height: fit-content;
  border-radius: 8px;
  transition: all 0.4s ease-in-out;
}

.section_third_heading {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

.td_digit {
  font-weight: 600;
}

.section_third_heading label {
  font-family: "Roboto", sans-serif;
  font-size: 24px;

  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.chart_container {
  width: 97%;
}

.header_second {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #888888;
}

/* Small screens */
@media (max-width: 520px) {
  .boxes_mobile {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: stretch;
  }

  .vert_line {
    height: 1px;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .container {
    padding: 2%;
    gap: 0.5rem;
  }

  .section_first,
  .section_second,
  .section_third {
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1rem;
    align-items: center;
    align-self: center;
    width: 100%;
  }

  .section_first_right {
    width: 100%;
  }

  .main_content {
    flex-direction: row;
    gap: 6px;
    margin: 16px 0;
  }

  .custom-table,
  .section_second_table,
  .section_second_box {
    width: 93%;
    padding: 22px;
  }

  .section_second_box {
    margin-left: 0px;
  }

  .custom-table tr th {
    font-size: 20px;
    font-weight: 500;
  }

  .custom-table td,
  .section_second_table .header,
  .section_second_table label {
    font-size: 16px;
    font-weight: 600;
  }

  .boxes {
    display: none;
  }

  .box {
    width: 110px;
    height: auto;
    padding: 10px;
  }

  .btn_primary {
    height: 40px;
    margin-top: 16px;
  }

  .section_third_container {
    padding: 14px;
    width: 93%;
    margin-top: 12px;
  }

  .section_third_heading {
    margin-bottom: 16px;
    font-size: 20px;
  }

  .risk-container {
    padding: 0 0px;
  }

  .risk-label {
    margin: 20px 0 0px 0;
    font-size: 16px;
    font-weight: 600;
  }

  .risk-slider {
    height: 15px;
  }

  .slider-labels {
    font-size: 14px;
  }

  .content p,
  .content_section p,
  .last_section_part p,
  .header_digit {
    font-size: 14px;
    font-weight: 500;
  }

  .icon_div {
    padding: 4px;
  }

  .section_third_image {
    width: 24px;
    height: 24px;
  }

  .section_third_main {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .header_second {
    font-size: 12px;
  }

  .content_section {
    justify-content: flex-start;
    gap: 10px;
  }

  .text_div {
    margin-top: 1px;
  }

  .text_div .title {
    font-size: 10px;
    font-weight: 500;
  }
  .text_div .subtitle {
    font-size: 16px;
    font-weight: 600;
  }

  .last_section {
    padding: 14px 4px;
  }

  .last_section_part {
    gap: 8px;
  }

  .risk-slider::-webkit-slider-thumb,
  .risk-slider::-moz-range-thumb {
    width: 10px;
    height: 10px;
  }

  .chart_container {
    width: 100%;
    padding-left: 10px;
  }

  .tr,
  .td {
    font-weight: 600;
  }
}
