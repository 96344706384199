.stage-cube-cont {
  width: 100%;
  height: 100%;
  top: 0;
  margin-top: 100px;
}

@media (max-width: 630px) {
  .stage-cube-cont {
    margin-top: 20px;
  }
}

.cubespinner {
  margin-top: auto;
  margin-bottom: auto;
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-left: calc(50% - 100px);
}

.cubespinner div {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  background: #cdffd8;
  text-align: center;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 20px 0px #94b9ff;
}

.face1 {
  transform: translateZ(100px);
  color: #dd0031;
}
.face2 {
  transform: rotateY(90deg) translateZ(100px);
  color: #f06529;
}
.face3 {
  transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
  color: #28a4d9;
}
.face4 {
  transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
  color: #5ed4f4;
}
.face5 {
  transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
  color: #efd81d;
}
.face6 {
  transform: rotateX(-90deg) translateZ(100px);
  color: #ec4d28;
}

@keyframes spincube {
  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  16% {
    transform: rotateY(-90deg);
  }
  33% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }
  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }
  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }
  83% {
    transform: rotateX(90deg);
  }
}
